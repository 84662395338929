import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueDOMPurifyHTML from 'vue-dompurify-html';

import { VueReCaptcha } from 'vue-recaptcha-v3'
//console.log(process.env.VUE_APP_RECAPTCHA_PUBLIC);

const config = { namedConfigurations: {
    allhtml: {
        USE_PROFILES: { html: true }
    },
    safehtml: {
        ALLOWED_TAGS: ['b', 'q','p','br','ul','li','span','div', 'h1', 'h2', 'h3']
    },
},
};

createApp(App).use(VueReCaptcha,{ siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC })
                .use(VueDOMPurifyHTML, config)
                .use(store)
                .use(router)
                .mount('#app');
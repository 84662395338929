import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/participate',
		name: 'Participate',
		component: () => import('../views/Participate.vue')
	},
	{
		path: '/gifts',
		name: 'Gifts',
		component: () => import('../views/Gifts.vue')
	},
	{
		path: '/winners',
		name: 'Winners',
		component: () => import('../views/Winners.vue')
	},
	{
		path: '/cookies',
		name: 'Cookies',
		component: () => import('../views/Cookies.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('../views/Contact.vue')
	},
	{
		path: '/finish',
		name: 'Finish',
		component: () => import('../views/Finish.vue')
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import('../views/Terms.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 }
	}
})

export default router

<template>
	<div id="app" class="uk-height-viewport">
		<div class="header">
			<div class="uk-container uk-flex uk-flex-left">
				<div class="menu-wrap">
					<div class="menu-toggle"  v-show="!openNav" uk-toggle="target: #sidenav" v-on:click="toggleMenu()">
						<svg viewBox="0 0 40 40" width="40" height="40">
							<rect y="0" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
							<rect y="16" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
							<rect y="32" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
						</svg>
					</div>
					<div class="menu-toggle" v-show="openNav" v-on:click="toggleMenu();">
						<svg viewBox="0 0 40 40" width="40" height="40">
							<line x1="0" y1="40" x2="40" y2="0" stroke="white" stroke-width="6"></line>
							<line x1="0" y1="0" x2="40" y2="40" stroke="white" stroke-width="6"></line>
						</svg>
					</div>
					<div class="menu-nav" v-show="openNav">
						<router-link to="/">Home</router-link>
						<router-link v-if="!finished" to="/participate">Πάρε Μέρος</router-link>
						<router-link to="/gifts">Δώρα</router-link>
						<router-link to="/winners">Νικητές</router-link>
						<router-link to="/terms">Όροι Διαγωνισμού</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="main">
			<router-view/>
		</div>
		<div id="tm-footer" class="uk-position-bottom uk-position-fixed">
			<div class="uk-container">
				<ul class="uk-subnav uk-flex-center uk-margin-remove uk-subnav-divider">
					<li><router-link to="/terms">Οροι Διαγωνισμου</router-link></li>
					<li><router-link to="/contact">ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</router-link></li>
				</ul>
			</div>
		</div>
	</div>
	</template>


<script>
import store from '@/store/';

import { useRoute, useRouter } from 'vue-router'
import { ref, watch, computed } from 'vue';
import UIkit from 'uikit';

export default {
	setup() {
		const openNav = ref('');
		const finished = computed(() => store.getters.getFinished);
	
		const closeOffcanvas = () => {
			UIkit.offcanvas("#offcanvas").hide();
		}
		const toggleMenu = () => {
			if (openNav.value == '') {
				openNav.value = 'open';
			} else {
				openNav.value = '';
			}
		}

		const route = useRoute()
		const router = useRouter()

		const storeInit = () => {
			store.dispatch('init')
			//console.log("finish " + finished.value);
			.then(() =>{
				setTimeout(() => {
					console.log("finish " + finished.value);
					if (route.fullPath === '/') {
						if (finished.value) {
							router.push({ path: 'finish' });
						}
					}
					console.log("Path " + route.fullPath);
				},300)
			
			});
		}

		storeInit();



		watch(route,
			(path) => {
				openNav.value = false;
				if (path.fullPath === '/') {
					if (finished.value) {
						router.push({ path: 'finish' });
					}
				} else if (path.fullPath === '/participate') {
					if (finished.value) {
						router.push({ path: 'finish' });
					}
				} else if (path.fullPath === '/finish') {
					if (!finished.value) {
						router.push({ path: '/' });
					}
				}
			}, {flush: 'pre', immediate: true, deep: true}
		)

		watch(finished,(newValue, oldValue)=>{
			console.log(`Updating from  ${oldValue} to ${newValue}`);
			///console.log(route.fullPath );
			if (route.fullPath === '/') {
				//console.log(`Updating from /  ${oldValue} to ${newValue}`);
				if (newValue) {
					router.push({ path: 'finish' });
				}
			} else if (route.fullPath === '/participate') {
				//console.log(`Updating from /participation ${oldValue} to ${newValue}`);
				if (newValue) {
					router.push({ path: 'finish' });
				}
			} else if (route.fullPath === '/finish') {
				//console.log(`Updating from /finish ${oldValue} to ${newValue}`);
				if (!newValue) {
					router.push({ path: '/' });
				}
			}
		})

		return {
			finished, openNav, closeOffcanvas, toggleMenu
		}
	},
}
</script>

<style lang="scss">
@import "assets/css/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

$global-font-family: 'PF Din Text Cond Pro','Open Sans', sans-serif;
$global-color: #364595;
$global-link-color: #2c3777;
$base-heading-color: #fff;
$base-body-color: #364595;
$base-font-family: 'PF Din Text Cond Pro';
$base-heading-font-family: 'PF Din Text Cond Pro';
$form-label-font-size: 25px;
$form-height: 70px;
$base-body-font-weight: 400;
$base-heading-font-weight: 700;
$container-xsmall-max-width: 710px;
$button-primary-background: #374494;
$button-primary-color: #fff;
$text-primary-color: #364595;
$button-padding-horizontal: 60px;
$button-font-size: 42px;
$button-line-height: 60px;
$base-h1-font-size-m: 68px;
$base-h1-font-size: 48px;
$base-h2-font-size-m: 48px;
$base-h3-font-size: 38px;
$form-radio-checked-icon-color: #00144f;
$form-radio-checked-background: #fff;
$form-color: #000;
$form-focus-color: #000;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() { color: #fff; }
@mixin hook-form() { font-size: 25px; }
@mixin hook-form-label() { line-height: 40px; font-size: 20px; margin-bottom: 5px; display: block; }
@mixin hook-button() { padding: 0 40px; text-transform: none; border-radius: 40px; }

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";

.header { top: 0; left: 0; right: 0; position: fixed; z-index: 3; width: 100%; height: 80px; }
.menu-wrap { width: 200px; text-align: center; padding-left: 40px; }
.menu-toggle { padding: 20px 0px 20px 0px; width: 110px;background-color: #ffdd00;}
.menu-toggle rect{fill:#374494 !important}
.menu-toggle line{stroke:#374494 !important;background-color: #fee600;}
.menu-nav { display: none; outline: none; line-height: 30px; height: 160px; background-color: #374494; display: flex; flex-direction: column; justify-items: center; padding: 20px; }
.menu-nav a{font-weight: bold;color: #fff;}
.menu-nav a:hover { color: #ffffffd1; text-decoration: underline; }

html { min-height: 100%; background-color: #ffdd00; padding-bottom: 60px; padding-top: 0px; /*background: url(assets/bg.jpg) top center #11417a no-repeat;  background-size:cover;background-origin:content-box;*/}

/*html.home { background: #ecd2af; }*/
body.no-footer-bg #bg::after { display: none; }
#nav { width: 100%; z-index: 3; color: #ffffff; max-height: 92px; position: fixed; top: 0; font-size: 20px; text-align: center; transition: max-height 0.45s ease-out, background-color 0.45s ease-out; }
.tm-offcanvas-btn { z-index: 3; position: fixed; top: 0; left: 0; padding: 0 10px; min-height: 60px; }
#nav.open { max-height: 600px; background-color: #0176d3; }
#nav a { outline: none; display: block; color: #000; padding: 5px 20px; font-size: 15px; font-weight: 800; }
#tm-footer { background-color: #00bdf1; font-size: 16px; line-height: 16px; padding: 14px 0 !important; text-transform: uppercase; font-weight: 700; z-index: 2; }
#tm-footer .uk-subnav > * > :first-child { font-size: 14px; color: #364595; padding: 10px 0; font-weight: 700; }
#tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before{border-left-color: #364595;}
.toggler > div { padding: 20px; display: inline-block; width: 200px; }
.toggler > div.closed { background-color: #ed1c24; }
.grecaptcha-badge { z-index: 2; }
.tm-button-tranformed { transform: skew(-20deg, -1deg); }
.tm-contest-dates {font-family: 'Myriad Pro'; font-size: 17px; line-height: 24px; font-weight: 600;}
.tm-contest-note{ font-size: 12px; line-height: 14px; font-weight: 600;}
.uk-text-danger { color: #ed1c24 !important; }
.uk-radio, .uk-checkbox { height: 26px; width: 26px; background-color: #ffffff;border-radius: 999px; }
.uk-radio:focus, .uk-checkbox:focus { border-color: #222; background-color: #ffffff; }
.uk-radio:checked:focus, .uk-checkbox:checked:focus, .uk-checkbox:indeterminate:focus { background-color:  #ffffff;  }
.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {background-color:  #ffffff;}
.uk-radio:checked { background: #231f20; }
.uk-radio:checked:after{ content: ''; width: 10px; height: 10px; border-radius: 50%; display: block; margin-top: 5px; margin-left: 5px; background-color:#fff; }
.uk-checkbox:checked:before{content: '\2713'; display: block;font-size:18px;  margin-left: 5px; color:#364595 }
#teconsent { z-index: 3; position: fixed; bottom: 50px; left: 0; }
.tm-background-menu { background-color: #0176d3; height: 80px; }
.uk-text-bold { font-weight: 800; }
label a:hover,label a{color:#364595;text-decoration: underline;}

.tm-color-black{color: black !important;}
.tm-color-blue{color: #364595 !important;}
.tm-color-white{color: #fff !important;}
.tm-color-yellow{color:#ffe507 !important;}
.tm-color-blueshadow{color:#00144f !important;text-shadow: 1px 1px 1px #ffe507;}
.trustarc-banner {position: fixed;bottom: 0;width: 100%;z-index: 9;}


.tm-text-slogan{color:#374494; font-size: 70px;line-height: 70px;}
.tm-text-slogan-sec{font-size: 120px;}

@media (max-width: 1200px) {
	.grecaptcha-badge { bottom: 60px !important; }
	#tm-footer .uk-subnav > * > :first-child { font-size: 13px; padding: 10px 14px; }
}

@media (max-width: 980px) {
	.tm-text-slogan{font-size: 50px;line-height: 50px;}
	.tm-text-slogan-sec{font-size: 70px;}

}

</style>
